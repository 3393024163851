@import "colors.scss";

$logow: 4rem;
$logoh: 1.5rem;

body,
html {
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
	/* Chrome all / Safari all */
	-moz-user-select: none;
	/* Firefox all */
	-ms-user-select: none;
	/* IE 10+ */
	user-select: none;
	/* Likely future */
}

.page {
	display: grid;
	place-items: center;
	width: 100vw;
	min-height: 100vh;
}

.container {
	position: relative;
	box-sizing: border-box;

	&.with-logo {
		&::before {
			content: "";
			background-image: url("./image/logo.svg");
			background-size: 100% 100%;
			position: absolute;
			top: -0.5rem;
			left: 0;
			color: white;
			width: $logow * 2;
			height: $logoh * 2;
		}
	}

	&.with-logo.general::before {
		background-image: url("./image/logo.svg") !important;
	}

	&.with-logo.rifiuti::before {
		background-image: url("./image/rifiuti.svg") !important;
	}

	&.with-logo.ecosostenibilita::before {
		background-image: url("./image/ecosostenibilita.svg") !important;
	}

	&.with-logo.energia::before {
		background-image: url("./image/energia.svg") !important;
	}

	&.with-logo.mobilita::before {
		background-image: url("./image/mobilita.svg") !important;
	}

	&.with-logo.biodiversita::before {
		background-image: url("./image/biodiversita.svg") !important;
	}
}

.start-menu {
	button {
		width: 14rem !important;
	}

	h1 {
		text-transform: uppercase;

		&.general {
			color: $general
		}

		&.rifiuti {
			color: $rifiuti
		}

		&.ecosostenibilita {
			color: $ecosostenibilita
		}

		&.energia {
			color: $energia
		}

		&.mobilita {
			color: $mobilita
		}

		&.biodiversita {
			color: $biodiversita
		}
	}
}

.banner {
	text-transform: uppercase;
	text-align: center;

	h2 {
		font-size: 1.5rem;
	}

	.logo {
		height: 6rem;
	}
}

.btn-start {
	height: 5rem;
	width: 5rem;
	margin-top: 10%;
}

.question,
.classifica,
.bonus {
	text-align: center;
}

.bonus,
.classifica {
	display: grid;
	justify-items: center;
}

.classifica h2 {
	margin-top: 0 !important;
	font-size: 22px;
}

.classifica .div-planets img.planets {
	width: 38%;
}

.answer {
	padding: 0.2rem .5rem;
	border: 1px solid black;
	margin-bottom: 0.6rem;
}

.circles-outer {
	position: absolute;
	display: grid;
	place-items: center;
	bottom: 0;
	left: 0;
	width: 100%;
}

.circle {
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 0.5rem;
	background: rgba(0, 0, 0, 0.15);
	display: inline-block;
	margin: 0 0.2rem;
	transition: all 0.2s ease-in-out;

	&.colored {
		background-color: $primary;
	}

	&.red {
		background-color: red;
	}

	&.green {
		background-color: green;
	}

	&.focus {
		background: rgba(0, 0, 0, 0.25);
		transform: scale(1.1);
	}
}

.error {
	display: none;
	text-align: center;
}

.show-error {
	display: block !important;
}

.home-page {
	display: flex;
	align-content: center;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
}

.container-btn {
	display: flex;
	align-content: center;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.question {
	display: flex;
	align-content: center;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}

.error-color {
	background-color: #F88379 !important;
}

.page {

	background-size: cover;

	&.general,
	&.acqua {
		background-image: url("./assets/sfondi/3840/generico.jpg");
	}

	&.biodiversita {
		background-image: url("./assets/sfondi/3840/biodiversita.jpg");
	}

	&.ecosostenibilita {
		background-image: url("./assets/sfondi/3840/ecosostenibilita.jpg");
	}

	&.energia {
		background-image: url("./assets/sfondi/3840/energia.jpg");
	}

	&.mobilita {
		background-image: url("./assets/sfondi/3840/mobilita.jpg");
	}

	&.rifiuti {
		background-image: url("./assets/sfondi/3840/rifiuti.jpg");
	}


}

.error-container {
	position: absolute;
}

@media screen and (max-device-aspect-ratio: 1/1) and (orientation: portrait) {
	.page {
		align-items: baseline;
	}

	.circles-outer {
		bottom: 0;
	}

	.container {
		width: calc(100vw - 2rem);
		padding: 2rem 2rem 3rem 2rem;
		background: white !important;

		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: -15rem;
			left: 0;
			width: 100%;
			height: 15rem;
			background-image: url("./image/fumetto-b.svg");
			background-size: 100% auto;
			background-position: center -1px;
			background-repeat: no-repeat;
		}

		&.with-logo {
			margin-top: 4.5rem;

			&::before {
				top: -4.5rem;
				left: 0;
				width: $logow * 2.5;
				height: $logoh * 2.5;
			}
		}
	}

	.question {
		font-size: 1.2rem;
	}

	.answers {
		font-size: 1rem;
		margin-top: 1rem;
	}

	.classifica .div-planets img.planets {
		width: 60%;
	}

	.countdown {
		margin-left: 10px;
	}

	.error-container {
		top: -130px;
	}
}

@media screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape) {
	.container {
		width: 45rem;
		height: 38rem;
		background-image: url("./image/fumetto-l.svg");
		background-size: 100% 100%;
		padding: 6rem 5rem 10rem 5rem;
	}

	.container.big {
		background-image: url("./image/fumetto-big.svg") !important;
		width: 68rem !important;
		height: 39rem !important;

		.volume {
			bottom: 0;
			right: 65px;
		}
	}

	.container.home-page {
		padding: 4rem 5rem 10rem 5rem !important;
	}

	.circles-outer {
		bottom: 11.5rem;
	}

	.question {
		font-size: 1.1rem;
	}

	.answers {
		font-size: 0.8rem;
		margin-top: 1rem;
		width: 26rem;
		margin-left: 4.5rem;
	}

	.countdown {
		position: absolute;
		right: 62px;
		top: 40px;
	}

	.error-container {
		top: -50px;
	}
}

.btn-categories {
	display: flex;
	align-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.volume {
	border: none;
	background: transparent;
}

.start-countdown {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.timer {
		font-size: 30px;
		font-weight: 900;
	}

	button {
		margin-top: 15px;
	}
}

.bonus .btn-bonus {
	display: flex;

	button {
		width: 5rem;
	}

	button:first-child {
		margin-right: 10px;
	}

	button:last-child {
		margin-left: 10px;
	}
}

.container-btn a {
	all: unset;
	margin-top: 10px;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 700;
}

.review {
	table {
		display: flex;
		align-items: center;
		flex-direction: column;

		thead h2 {
			line-height: 0.5;
		}

		th.left {
			text-align: left !important
		}
	}

}

.scrollbar {
	max-height: 18rem;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		margin-left: 10px;
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0px grey;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 10px;
	}
}

.quiz-end {
	text-align: center;

	h2 {
		margin: 0;
		font-size: 20px
	}

	p {
		margin-bottom: 10px;
	}
}