@import 'colors.scss';

.istruzioni{
	h1{
		display: flex;
		justify-content: center;
		margin: 0;
	}

	ul{
		font-size: 14px;
		margin: 0 !important;
	}

	p{
		display: flex;
		justify-content: center;
		margin: 0;
	}

	.btn-container-wrap{
		display: flex;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}
}