@import 'colors.scss';

body,
html {
	font-family: 'EasyReadingPRO', Georgia;
	line-height: 1.84;
}

.loader {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.page {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	align-content: center;
	overflow: hidden;

	.login,
	.sign-in {
		justify-content: center;

		form {
			display: grid;
			justify-items: center;
		}
	}

	.home {
		display: grid;
		justify-items: center;
		line-height: 5px;
	}

	.home-page {
		align-items: center;
	}
}

.login,
.sign-in {
	input {
		background-color: #eee;
		border: none;
		border-radius: 10px;
		padding: 12px 15px;
		// margin: 8px 0;
		width: 100%;
	}
}

.submit,
a button,
.btn-categories button,
.classifica button,
.bonus button,
.container-btn button {
	font-family: 'EasyReadingPRO', Georgia;
	background-color: $homebtn;
	border: none !important;
	border-radius: 50px !important;
	height: 3rem;
	width: 12rem;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1rem;
	position: relative !important;
	margin-top: 10px;
	vertical-align: middle;
	margin: 4px 0;

	&::before {
		position: absolute !important;
		display: block !important;
		content: "" !important;
		border-radius: 50px !important;
		border: 2px solid white !important;
		z-index: 1 !important;
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
	}
}

.link-change {
	display: flex;
	align-items: center;
	flex-direction: column;
}

button,
a,
.answer {
	cursor: pointer !important;
}

.login button.submit.general,
.sign-in button.submit.general {
	margin-top: 15px
}

.home .home-logo {
	width: 60%;
	max-height: 10rem;
}

.volume {
	position: absolute;
	right: 50px;
	bottom: 90px;
}

.home-btn-container{
	display: flex;
}

@media screen and (max-device-aspect-ratio: 1/1) and (orientation: portrait) {
	.volume {
		right: 5px;
		bottom: 0;
	}

	.home-btn-container{
		flex-direction: column;
	}
}

@media screen and (min-device-aspect-ratio: 1/1) and (orientation: landscape) {
	.home-btn-container{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
	}
}

.welcome {
	line-height: normal !important;
	font-size: 20px;
	text-align: center;

}
