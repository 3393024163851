$primary: #3964A5;
$bgcolor: #63C988;
$homebtn: #3c3c3b;

$general: #00a077;
$biodiversita: #e4242e;
$ecosostenibilita: #9bbe1d;
$energia: #f5a100;
$mobilita: #0081c2;
$rifiuti: #CF63CE;
$acqua: #00A2DD;