/* Webfont: EasyReadingPRO */@font-face {
    font-family: 'EasyReadingPRO';
    src: url('EasyReadingPRO.eot'); /* IE9 Compat Modes */
    src: url('EasyReadingPRO.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('EasyReadingPRO.woff') format('woff'), /* Modern Browsers */
         url('EasyReadingPRO.ttf') format('truetype'); /* Safari, Android, iOS */
             font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


/* Webfont: EasyReadingPRO-Bold */@font-face {
    font-family: 'EasyReadingPRO';
    src: url('EasyReadingPROBold.eot'); /* IE9 Compat Modes */
    src: url('EasyReadingPROBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('EasyReadingPROBold.woff') format('woff'), /* Modern Browsers */
         url('EasyReadingPROBold.ttf') format('truetype'); /* Safari, Android, iOS */
             font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: EasyReadingPRO-BoldItalic */@font-face {
    font-family: 'EasyReadingPRO';
    src: url('EasyReadingPROBoldItalic.eot'); /* IE9 Compat Modes */
    src: url('EasyReadingPROBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('EasyReadingPROBoldItalic.woff') format('woff'), /* Modern Browsers */
         url('EasyReadingPROBoldItalic.ttf') format('truetype'); /* Safari, Android, iOS */
             font-style: italic;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}