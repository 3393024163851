@import 'colors.scss';

.ranking {
	table {
		border-collapse: collapse;
	}

	tbody {
		// text-align: left;
	}

	td {
		padding: 0 10px;

		&.score {
			text-align: right;
		}
	}
}

.classifica {
	h1 {
		margin: 0;
	}
}

.self-ranking {
	border-top: 1px solid $homebtn;
	padding-top: 2px;
}